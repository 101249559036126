// extracted by mini-css-extract-plugin
export var customText = "t_r6 d_dv d_cs d_cg";
export var videoIframeStyle = "t_p3 d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "t_r7 d_cs d_cg d_Z";
export var customRow = "t_qn d_bD d_bf";
export var quoteWrapper = "t_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "t_p7 d_H";
export var masonryImageWrapper = "t_qf";
export var title = "t_r8";
export var Title3Small = "t_r9 J_r9 J_vg J_vp";
export var Title3Normal = "t_sb J_sb J_vg J_vq";
export var Title3Large = "t_sc J_sc J_vg J_vr";