// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "C_tY d_gS d_cw d_dv";
export var quoteParagraphCenter = "C_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "C_gV d_gV d_cw d_dx";
export var quoteRowLeft = "C_tZ d_bG";
export var quoteRowCenter = "C_t0 d_bD";
export var quoteRowRight = "C_t1 d_bH";
export var quoteWrapper = "C_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "C_gR d_gR";
export var quoteExceptionSmall = "C_t2 J_t2";
export var quoteExceptionNormal = "C_t3 J_t3";
export var quoteExceptionLarge = "C_t4 J_t4";
export var quoteAuthorExceptionSmall = "C_t5 J_t5";
export var quoteAuthorExceptionNormal = "C_t6 J_t6";
export var quoteAuthorExceptionLarge = "C_t7 J_t7";