// extracted by mini-css-extract-plugin
export var alignLeft = "q_q2 d_fp d_bG d_dv";
export var alignCenter = "q_bP d_fq d_bD d_dw";
export var alignRight = "q_q3 d_fr d_bH d_dx";
export var comparisonContainer = "q_q4 d_dX";
export var comparisonContainerFull = "q_q5 d_dV";
export var comparisonFloatyBox = "q_h1 d_h1 d_by d_Z d_cz";
export var comparisonSubtitle = "q_jb d_jb d_w d_c5";
export var comparisonRow = "q_h6 d_h6 d_cc d_bJ";
export var comparisonMainHeader = "q_h7 d_h7 d_w d_cw";
export var comparisonComponentText = "q_h3 d_h3 d_w";
export var comparisonBtnWrapper = "q_jj d_jj d_d1 d_w d_bz";
export var comparisonBtnWrapperSecond = "q_jk d_jk d_w";
export var comparisonImageContainer = "q_jg d_jg d_Z d_by d_w";
export var stretch = "q_q6";
export var limit = "q_q7";
export var exceptionWeight = "q_q8 J_vJ";