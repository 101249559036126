// extracted by mini-css-extract-plugin
export var dark = "s_rp";
export var darkcookie = "s_rq";
export var tintedcookie = "s_rr";
export var regularcookie = "s_rs";
export var darkbase = "s_rt";
export var tintedbase = "s_rv";
export var regularbase = "s_rw";
export var darkraw = "s_rx";
export var tintedraw = "s_ry";
export var regularraw = "s_rz";
export var darkchick = "s_rB";
export var tintedchick = "s_rC";
export var regularchick = "s_rD";
export var darkherbarium = "s_rF";
export var tintedherbarium = "s_rG";
export var regularherbarium = "s_rH";
export var darkholiday = "s_rJ";
export var tintedholiday = "s_rK";
export var regularholiday = "s_rL";
export var darkoffline = "s_rM";
export var tintedoffline = "s_rN";
export var regularoffline = "s_rP";
export var darkorchid = "s_rQ";
export var tintedorchid = "s_rR";
export var regularorchid = "s_rS";
export var darkpro = "s_rT";
export var tintedpro = "s_rV";
export var regularpro = "s_rW";
export var darkrose = "s_rX";
export var tintedrose = "s_rY";
export var regularrose = "s_rZ";
export var darktimes = "s_r0";
export var tintedtimes = "s_r1";
export var regulartimes = "s_r2";
export var darkwagon = "s_r3";
export var tintedwagon = "s_r4";
export var regularwagon = "s_r5";