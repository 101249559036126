// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "v_sd d_bD";
export var datasheetRowWrapper = "v_lt d_lt d_bK";
export var datasheetLeftWrapper = "v_sf d_bz d_bP";
export var datasheetWrapperFull = "v_sg d_cD";
export var datasheetWrapperFullLeft = "v_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "v_mv d_lv";
export var contentWrapperCenter = "v_sh d_lz";
export var datasheetLeftWrapperCenter = "v_sj d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "v_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "v_sk d_lw";
export var datasheetFooter = "v_ly d_ly";
export var alignLeft = "v_q2 d_dv";
export var alignCenter = "v_bP d_dw";
export var alignRight = "v_q3 d_dx";
export var datasheetInnerWrapperNewLeft = "v_sl d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "v_sm d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "v_sn d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "v_sp d_bK";
export var datasheetSubtitle = "v_sq d_cx";
export var tableRow = "v_sr";
export var cell = "v_ss";
export var tableRowWrapper = "v_st d_w d_dv";
export var tableHeadingCell = "v_sv";
export var tableHeading = "v_sw";
export var tableRowStriped = "v_sx";
export var tableRowHeading = "v_sy";
export var dataTable = "v_sz d_w";
export var imageWrapper = "v_sB d_fg d_Z";
export var imageWrapperFull = "v_sC d_H d_w d_bf d_Z";
export var imageWrapperIcon = "v_sD";
export var titleMargin = "v_sF d_cs";
export var datasheetInnerInnerWrapper = "v_sG d_w";
export var hasLabels = "v_sH";
export var label = "v_sJ";
export var SmallSmall = "v_sK J_sK J_vg J_vD";
export var SmallNormal = "v_sL J_sL J_vg J_vF";
export var SmallLarge = "v_sM J_sM J_vg J_vB";