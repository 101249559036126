// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_tx d_gv d_cs";
export var heroHeaderCenter = "z_gw d_gw d_cs d_dw";
export var heroHeaderRight = "z_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "z_ty d_gr d_cw";
export var heroParagraphCenter = "z_gs d_gs d_cw d_dw";
export var heroParagraphRight = "z_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "z_tz d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "z_tB d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "z_tC d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "z_tD d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "z_tF d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "z_tG J_tG";
export var heroExceptionNormal = "z_tH J_tH";
export var heroExceptionLarge = "z_tJ J_tJ";
export var Title1Small = "z_tK J_tK J_vg J_vh";
export var Title1Normal = "z_tL J_tL J_vg J_vj";
export var Title1Large = "z_tM J_tM J_vg J_vk";
export var BodySmall = "z_tN J_tN J_vg J_vz";
export var BodyNormal = "z_tP J_tP J_vg J_vB";
export var BodyLarge = "z_tQ J_tQ J_vg J_vC";