// extracted by mini-css-extract-plugin
export var carouselContainer = "M_pD d_w d_H d_bf d_Z";
export var carouselContainerCards = "M_ww M_pD d_w d_H d_bf d_Z";
export var carouselContainerSides = "M_wx d_w d_H d_Z";
export var prevCarouselItem = "M_wy d_w d_H d_0 d_k";
export var prevCarouselItemL = "M_wz M_wy d_w d_H d_0 d_k";
export var moveInFromLeft = "M_wB";
export var prevCarouselItemR = "M_wC M_wy d_w d_H d_0 d_k";
export var moveInFromRight = "M_wD";
export var selectedCarouselItem = "M_wF d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "M_wG M_wF d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "M_wH M_wF d_w d_H d_Z d_bf";
export var nextCarouselItem = "M_wJ d_w d_H d_0 d_k";
export var nextCarouselItemL = "M_wK M_wJ d_w d_H d_0 d_k";
export var nextCarouselItemR = "M_wL M_wJ d_w d_H d_0 d_k";
export var arrowContainer = "M_wM d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "M_wN M_wM d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "M_wP M_wN M_wM d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "M_wQ M_wM d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "M_wR";
export var nextArrowContainerHidden = "M_wS M_wQ M_wM d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "M_kG d_0";
export var prevArrow = "M_wT M_kG d_0";
export var nextArrow = "M_wV M_kG d_0";
export var carouselIndicatorContainer = "M_wW d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "M_wX d_w d_bz d_bF";
export var carouselText = "M_wY d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "M_wZ M_wY d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "M_w0 d_b7";
export var carouselIndicator = "M_w1 M_w0 d_b7";
export var carouselIndicatorSelected = "M_w2 M_w0 d_b7";
export var arrowsContainerTopRight = "M_w3 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "M_w4 d_0 d_bl d_bC";
export var arrowsContainerSides = "M_w5 d_0 d_bl d_bC";
export var smallArrowsBase = "M_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "M_w7 M_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "M_w8 M_w7 M_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "M_w9 M_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "M_xb M_w9 M_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "M_qm";
export var iconSmall = "M_xc";
export var multipleWrapper = "M_xd d_bC d_bF d_bf";
export var multipleImage = "M_xf d_bC";
export var sidesPrevContainer = "M_xg M_w7 M_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "M_xh M_w7 M_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";