// extracted by mini-css-extract-plugin
export var lbContainerOuter = "N_xj";
export var lbContainerInner = "N_xk";
export var movingForwards = "N_xl";
export var movingForwardsOther = "N_xm";
export var movingBackwards = "N_xn";
export var movingBackwardsOther = "N_xp";
export var lbImage = "N_xq";
export var lbOtherImage = "N_xr";
export var prevButton = "N_xs";
export var nextButton = "N_xt";
export var closing = "N_xv";
export var appear = "N_xw";