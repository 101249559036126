// extracted by mini-css-extract-plugin
export var socialContainer = "K_v9";
export var instagramLink = "K_wb";
export var socialWrapperLeft = "K_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "K_wc";
export var socialContentContainerFull = "K_wd";
export var instagramContainer = "K_wf";
export var twitterContainer = "K_wg";
export var facebookContainer = "K_wh";
export var socialErrorContainer = "K_wj";
export var facebookContainerWide = "K_wk";
export var twitterContainerWide = "K_wl";
export var socialParagraphCenter = "K_wm";
export var instaWrapper = "K_wn";
export var SubtitleSmall = "K_qq J_qq J_vg J_vs";
export var SubtitleNormal = "K_qr J_qr J_vg J_vt";
export var SubtitleLarge = "K_qs J_qs J_vg J_vv";
export var textLeft = "K_dv";
export var textCenter = "K_dw";
export var textRight = "K_dx";